// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
@import "_plugins";
@import "monokai.css";



// - - - - - - - - - - Variables


// Colors

$background-color: #ffffff;
$background-alt-color: #F5F7FA;
$border-color: #dddddd;

$highlight_color: #CFCFCF;
$inline-code-color: #000000;
$text-dark-color: #0000a0;
$text-medium-color: #1C1C1C;
$text-light-color: #ABB7B7;

$accent-color: #A2DED0;

$overlay-background-color: #161B21;
$overlay-text-color: #ffffff;

$error-color: #D64541;


// Fonts

$body-font: "Open Sans",Helvetica,sans-serif;;
$title-font: "Baloo Bhaina 2", cursive;;
$blog-title-font: "Baloo Bhaina 2", cursive;;
$blog-subtitle-font: "Baloo Bhaina 2", cursive;;
$tagline-font: "Baloo Bhaina 2", cursive;;
$menu-font: "Baloo Bhaina 2", cursive;;
$regular-weight: 400;
$bold-weight: 600;


// Typography

$sitetitle-small: 3em;
$sitetitle-medium: 3em;
$sitetitle-large: 3em;
$sitetitle-weight: 100;
$sitetitle-spacing: 0em;

$sitetagline-small: 1em;
$sitetagline-medium: 1em;
$sitetagline-large: 1em;
$sitetagline-weight: 300;
$sitetagline-spacing: 0em;
$sitetagline-lineheight: 1.5;

$menu-small: 20px;
$menu-medium: 25px;
$menu-large: 30px;
$menu-weight: 400;
$menu-spacing: 0em;

$blogtitle-small: 6vw;
$blogtitle-medium: 6vw;
$blogtitle-large: 6vw;
$blogtitle-weight: 300;
$blogtitle-spacing: 0em;

$blogsubtitle-small: 5vwpx;
$blogsubtitle-medium: 6vwpx;
$blogsubtitle-large: 7vwpx;
$blogsubtitle-weight: 100;
$blogsubtitle-spacing: 0em;

$h1-small: 7vh;
$h1-medium: 7vh;
$h1-large: 7vh;
$h1-weight: 400;
$h1-spacing: -0.02em;
$h1-lineheight: 1.2em;

$h2-small: 4vh;
$h2-medium: 4vh;
$h2-large: 4vh;
$h2-weight: 400;
$h2-spacing: -0.02em;
$h2-lineheight: 1.2;

$h3-small: 3vh;
$h3-medium: 3vh;
$h3-large: 3vh;
$h3-weight: 400;
$h3-spacing: -0.02em;
$h3-lineheight: 1.3;

$h4-small: 2.5vh;
$h4-medium: 2.5vh;
$h4-large: 2.5vh;
$h4-weight: 400;
$h4-spacing: 0em;
$h4-lineheight: 1.6;

$h5-small: 2vh;
$h5-medium: 2vh;
$h5-large: 2vh;
$h5-weight: 400;
$h5-spacing: 0em;
$h5-lineheight: 1.6;

$h6-small: 1.5vh;
$h6-medium: 1.5vh;
$h6-large: 1.5vh;
$h6-weight: 400;
$h6-spacing: 0em;
$h6-lineheight: 1.6;

$p-small: 15vwpx;
$p-medium: 5vwpx;
$p-large: 5vwpx;
$p-weight: 0;
$p-spacing: 0em;
$p-lineheight: 1.2;

$blockquote-small: 25px;
$blockquote-medium: 35px;
$blockquote-large: 45px;
$blockquote-weight: 400;
$blockquote-spacing: -0.02em;
$blockquote-lineheight: 1.6;

$button-weight: 600;
$button-spacing: 0em;


// Header
$overlay-opacity: 0.7;


// Logo
$logo-width: 200px;
$footer-logo-width: px;


// Grid item
$grid-spacing: 10px;



// - - - - - - - - - - Global styles

@import "_basic";

@import "_includes/_header";
@import "_includes/_footer";

@import "_includes/_content";
@import "_includes/_socials";
@import "_includes/_gallery";
@import "_includes/_syntax";
@import "_includes/_contact-form";



// - - - - - - - - - - Section styles

@import "_includes/_portfolio";
@import "_includes/_blog";
