// - - - - - - - - - - - - - - - - - -

// Blog

// - - - - - - - - - - - - - - - - - -

.blog {
  margin-top: 0;
  padding: 20px 0 0;

  @include mq(tabletp) {
    padding: 35px 0 0;
  }

  @include mq(tabletl) {
    padding: 35px 40px 0;
  }

  @include mq(laptop) {
    padding: 53px 60px 0;
  }
}

.blog-post {
  margin-top: 30px;

  @include mq(phonel) {
    margin-top: 10px;
  }

  @include mq(tabletp) {
    margin-top: 10px;
  }

  @include mq(tabletl) {
    margin-top: 10px;
  }

  @include mq(laptop) {
    margin-top: 10px;
  }

  @include mq(desktop) {
    margin-top: 10px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.blog-post__image {
  margin-top: 20px;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mq(tabletp) {
    margin-top: 25px;
  }

  @include mq(tabletl) {
    margin-top: 30px;
  }

  @include mq(laptop) {
    margin-top: 40px;
  }
}

.blog-post__header {
  padding-top: 0.25em;
  padding-left: 0.5em;
  -webkit-backdrop-filter: blur(10px)  contrast(0.9) opacity(0.5);
  backdrop-filter: blur(5px) contrast(0.1) opacity(0.5);
}

.blog-post__header_right {
  padding-top: 0.25em;
  padding-right: 0.5em;
  -webkit-backdrop-filter: blur(10px)  contrast(0.9) opacity(0.5);
  backdrop-filter: blur(10px)  contrast(0.9) opacity(0.5);
  text-align: right;
}

.blog-post__thumb {
        margin-top: 5px;
        display: block;
        width: 100%;
        height: 150px;
        padding-bottom: 7%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include mq(tabletp) {
                margin-top: 10px;
        }

        @include mq(tabletl) {
                margin-top: 5px;
        }

        @include mq(laptop) {
                margin-top: 15px;
                height: 10vh;
        }
}

.blog-post__content {
  font-family: $blog-subtitle-font;
  margin-top: 0.5em;

  @include mq(tabletp) {
    margin-top: 0.5em;
  }

  @include mq(tabletl) {
    margin-top: 0.5em;
  }

  @include mq(laptop) {
    margin-top: 0.5em;
  }
}

.blog-post__content_right {
  font-family: $blog-subtitle-font;
  margin-top: 0.5em;
  text-align: right;

  @include mq(tabletp) {
    margin-top: 5px;
  }

  @include mq(tabletl) {
    margin-top: 0.5em;
  }

  @include mq(laptop) {
    margin-top: 0.5em;
  }
}


.blog-post__title {
  font-family: $blog-title-font;
  margin-inline-end: 0px;
}

.blog-post__subtitle {
  font-family: $blog-subtitle-font;
  margin-top: 5px;
  color: $text-light-color;
}

.pagination {
  margin-top: 0;
  padding: 40px 20px 0;

  @include mq(tabletp) {
    padding: 55px 40px 0;
  }

  @include mq(tabletl) {
    padding: 75px 40px 0;
  }

  @include mq(laptop) {
    padding: 93px 60px 0;
  }
}

.pagination__prev,
.pagination__next {
  width: 50%;

  .button {
    display: block;
    text-align: center;
  }
}

.pagination__prev {
  float: left;
  padding-right: $grid-spacing/2;
}

.pagination__next {
  float: right;
  padding-left: $grid-spacing/2;
}
