// - - - - - - - - - - - - - - - - - -

// Header + menu

// - - - - - - - - - - - - - - - - - -



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Header

.header {
  padding: 40px 20px;
  max-width: 250px;
  background: $overlay-background-color;

  @include mq(tabletp) {
    padding: 40px 20px;
    max-width: 100%;
  }

  @include mq(phonel) {
    padding: 40px 20px;
    max-width: 100%;
    max-height: calc(min(10%, 150px));
  }

  @include mq(phonep) {
    max-width: 100%;
    max-height: calc(min(10%, 150px));
  }

  @include mq(tabletl) {
    position: fixed;
    z-index: 10;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20%;
    max-width: 250px;
    max-height: 100%;
    padding: 0;
  }

  @include mq(laptop) {
    width: calc(min(20%, 250px));
    max-height: 100%;
  }
  @include mq(desktop) {
    width: calc(min(20%, 250px));
    max-height: 100%;
  }

}

.header-image {
  position: absolute;
  max-width: 250px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  @include transition(opacity 0.4s ease-in-out);

  @include mq(phonel) {
    max-width: 100%;
  }

  @include mq(phonep) {
    max-width: 100%;
  }

  @include mq(tabletp) {
    max-width: 100%;
  }

  @include mq(tabletl) {
    position: fixed;
    right: auto;
    width: calc(min(20%, 250px));
  }

  @include mq(desktop) {
    width: calc(min(20%, 250px));
  }
}

.header-image--on {
  opacity: 1;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($overlay-background-color, $overlay-opacity);

  @include mq(tabletl) {
    background: -moz-linear-gradient(top, rgba($overlay-background-color, 0) 0%, rgba($overlay-background-color, 0.01) 1%, rgba($overlay-background-color, $overlay-opacity) 70%, rgba($overlay-background-color, $overlay-opacity) 100%);
    background: -webkit-linear-gradient(top, rgba($overlay-background-color, 0) 0%, rgba($overlay-background-color, 0.01) 1%, rgba($overlay-background-color, $overlay-opacity) 70%, rgba($overlay-background-color, $overlay-opacity) 100%);
    background: linear-gradient(to bottom, rgba($overlay-background-color, 0) 0%, rgba($overlay-background-color, 0.01) 1%, rgba($overlay-background-color, $overlay-opacity) 70%, rgba($overlay-background-color, $overlay-opacity) 100%);
  }
}

.header__content {
  position: absolute;
  white-space: nowrap;
  text-align: right;

  @include mq(phonep) {
    position: absolute;
    padding: 0px 2em;
    margin: 0 auto;
    text-align: right;
    right: 0;
    top: 0;
  }
  @include mq(phonel) {
    position: absolute;
    padding: 0px 2em;
    margin: 0 auto;
    text-align: right;
    right: 0;
    top: 0;
  }

  @include mq(tabletl) {
    position: absolute;
    left: 0;
    bottom: 0;
    top: unset;
    padding: 40px 40px 34px;
  }

  @include mq(laptop) {
    position: absolute;
    left: 0;
    bottom: 0;
    top: unset;
    padding: 40px 40px 34px;
  }
}

.header__logo {
  display: block;
  width: $logo-width;
  max-width: 80%;

  @include mq(tabletp) {
    float: left;
    max-width: 50%;
  }
}

.header__logo__img {
  width: 100%;
  height: auto;
}

.header__title {
  max-width: 250px;
  font-family: $title-font;
  font-size: $sitetitle-small;
  font-weight: $sitetitle-weight;
  letter-spacing: $sitetitle-spacing;
  line-height: 1;
  color: $overlay-text-color;

  &:hover {
    color: $overlay-text-color;
  }

  @include mq(tabletp) {
    font-size: $sitetitle-medium;
  }

  @include mq(laptop) {
    font-size: $sitetitle-large;
  }
}

.header__tagline {
  text-align: right;
  font-family: $tagline-font;
  font-size: $sitetagline-small;
  font-weight: $sitetagline-weight;
  letter-spacing: $sitetagline-spacing;
  line-height: $sitetagline-lineheight;
  color: rgba($overlay-text-color, 0.7);

  @include mq(tabletp) {
    font-size: $sitetagline-medium;
  }

  @include mq(laptop) {
    font-size: $sitetagline-large;
  }
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Menu

.menu {

  @include mq(tabletl) {
    margin-top: 20px;
    font-family: $menu_font;
    font-size: $menu-medium;
  }

  @include mq(laptop) {
    margin-top: 30px;
    font-family: $menu_font;
    font-size: $menu-large;
  }
}

.menu__toggle {
  position: absolute;
  z-index: 1002;
  top: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;

  @include mq(tabletl) {
    display: none;
  }
}

.menu__toggle__icon {
  position: absolute;
  right: 0;
  top: 2em;
  width: 1.5em;
  height: 1em;
  margin: 0 auto;

  span, span:before, span:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 15px;
    border-radius: 3px;
    height: 3px;
    @include transition(all 0.1s);
    background: rgba($overlay-text-color, 0.7);
  }

  span:before {
    top: -8px;
  }

  span:after {
    top: 8px;
  }
}

.menu--open .menu__toggle__icon {

  span:before, span:after {
    background: $overlay-text-color !important;
  }

  span {
    background-color: transparent !important;
  }

  span:before,
  span:after {
    top: 0;
  }

  span:before {
    @include rotate(45);
  }

  span:after {
    @include rotate(-45);
  }
}

.menu__wrap {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($overlay-background-color,0.95);
  overflow: auto;

  visibility: hidden;
  opacity: 0;
  @include transition(all 0.2s ease-in-out);

  @include mq(tabletl) {
    position: relative;
    background: transparent;
    visibility: visible;
    overflow: visible;
    opacity: 1;
    @include transition(none);
  }
}

.menu--open .menu__wrap {
  visibility: visible;
  opacity: 1;
}

.menu__list {
  font-family: $menu-font;
  list-style: none;
  margin: 0 auto;
  padding: 1em 3em;

  @include mq(laptop) {
    padding: 0;
    font-size: $menu-medium;
  }

  @include mq(desktop) {
    font-size: $menu-large;
    margin: 0;
    padding: 0;
  }
}

.menu__list__item {

}

.menu__list__item__link {
  font-size: $menu-small;
  font-weight: $menu-weight;
  color: rgba($overlay-text-color, 0.7);
  padding: 10px 0;

  &:hover {
    color: $overlay-text-color;
  }

  @include mq(tabletl) {
    padding: 0;
    font-size: $menu-medium;
  }

  @include mq(laptop) {
    font-size: $menu-large;
  }

  &.active-link {
    color: $overlay-text-color;
  }
}

.menu__button {
  margin-top: 15px;
  padding: 15px 20px 16px;
  font-size: 13px;

  @include mq(tabletl) {
    margin-top: 0;
    padding: 11px 20px 12px;
    font-size: 15px;
  }
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Socials

.header .socials {
  padding: 12px 20px;

  @include mq(tabletp) {
    padding: 10px 40px;
  }

  @include mq(tabletl) {
    margin: 27px 0 0;
    padding: 0;
  }

  @include mq(laptop) {
    margin-top: 35px;
  }

  .socials__item__link {
    background: rgba($overlay-text-color, 0.7);
    color: $overlay-background-color;

    &:hover {
      background: $overlay-text-color;
      color: $overlay-background-color;
    }
  }
}
