// - - - - - - - - - - - - - - - - - -

// Single page/post/project

// - - - - - - - - - - - - - - - - - -

.intro {
  margin-top: 0;
  padding: 20px 0 0;

  @include mq(tabletp) {
    padding: 35px 0 0;
  }

  @include mq(tabletl) {
    padding: 35px 40px 0;
  }

  @include mq(laptop) {
    padding: 53px 60px 0;
  }

  .wrap {
    margin-left: 0px;
    margin-top: 1em;

    @include mq(tabletl) {
      max-width: 80%;
    }

    @include mq(laptop) {
      max-width: 75%;
    }

    @include mq(desktop) {
      max-width: 70%;
    }
  }

  h1 {
    @include mq(tabletp) {
      white-space: pre-line;
    }
  }

  p {
    margin-top: 5px;
    color: $text-light-color;
  }
}

.single {
  margin-top: 0;
  padding: 20px 0;
  min-height: 100vh;

  @include mq(tabletp) {
    padding: 40px 0;
  }

  @include mq(tabletl) {
    padding: 30px 40px 40px;
  }

  @include mq(laptop) {
    padding: 1em 60px 60px;
  }

  h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin-left: 0px;
    margin-right: 0px;

    @include mq(tabletp) {
    }

    @include mq(tabletl) {
      max-width: 100%;
    }

    @include mq(laptop) {
      max-width: 100%;
    }

    @include mq(desktop) {
      max-width: 100%;
    }
  }

  hr {
    margin: 20px auto;

    @include mq(tabletp) {
      margin-top: 40px;
      margin-bottom: 40px;
      width: auto;
    }

    @include mq(tabletl) {
      max-width: 80%;
    }

    @include mq(laptop) {
      max-width: 75%;
    }

    @include mq(desktop) {
      max-width: 70%;
    }
  }

  blockquote,
  figure {
    margin: 20px auto;

    @include mq(tabletp) {
      margin: 30px auto;
    }

    @include mq(laptop) {
      margin: 40px auto;
    }
  }

  blockquote p {
    max-width: 100%;
    padding: 0;
  }

  p a {
    font-weight: $bold-weight;
    /*border-bottom: 2px solid $accent-color;*/
  }

  p .button {
    margin-top: 10px;
  }

  .highlighter-rouge,
  table {
    margin: 20px auto;
    width: 100%;

    @include mq(tabletp) {
      margin: 30px auto;
    }

    @include mq(tabletl) {
      max-width: 80%;
    }

    @include mq(laptop) {
      margin: 40px auto;
      max-width: 75%;
    }

    @include mq(desktop) {
      max-width: 70%;
    }
  }

  .highlight {
    display: block;
    padding: 0;
    line-height: 1.5;
    font-size: $p-small*0.85;
    line-height: $p-lineheight;
    overflow: auto;

    @include mq(tabletp) {
      font-size: $p-medium*0.85;
    }

    @include mq(laptop) {
      font-size: $p-large*0.85;
    }

    .highlight {
      padding: 20px;

      @include mq(tabletp) {
        padding: 30px;
      }

      @include mq(laptop) {
        padding: 40px;
      }
    }
  }

  table {

    thead {
      background: white;
      color: $background-color;
      text-align: left;
      font-weight: $bold-weight;
      border: 0px solid white;
      border-collapse: collapse;

      th {
        font-weight: $bold-weight;
        border: 0px solid white;
        border-collapse: collapse;
      }
    }

    th {
      border: 0px solid white;
      border-collapse: collapse;
      padding: 10px;
      font-size: $p-small;
      font-weight: $p-weight;

      @include mq(tabletp) {
        padding: 15px;
      }

      @include mq(laptop) {
        padding: 20px;
      }
    }

    td {
      padding: 10px;
      font-size: $p-small;
      font-weight: $p-weight;

      @include mq(tabletp) {
        padding: 15px;
      }

      @include mq(laptop) {
        padding: 20px;
      }
    }

    tr {

      &:nth-child(2n) {
        background: $background-alt-color;
      }
    }
  }

  sup a.footnote {
    display: inline-block;
    margin-left: 5px;
    min-width: 16px;
    height: 16px;
    border-radius: 100%;
    border-bottom: none;
    padding: 1px 3px;
    background: $background-alt-color;
    font-size: 10px;
    text-align: center;
    color: $text-light-color;

    &:hover {
      background: $text-light-color;
      color: $background-color;
    }
  }

  .footnotes {
    margin-top: 40px;

    @include mq(tabletp) {
      margin-top: 60px;
    }

    @include mq(laptop) {
      margin-top: 80px;
    }

    ol {
      list-style: none;
      counter-reset: footnotes;

      li {
        margin-top: 5px;
        font-size: 13px;
        counter-increment: footnotes;

        &:before {
          content: counter(footnotes);
            box-sizing: border-box;
          display: inline-block;
          margin-right: 3px;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          border-bottom: none;
          padding: 2px 3px;
          background: $background-alt-color;
          font-size: 11px;
          font-weight: $bold-weight;
          text-align: center;
          color: $text-light-color;
        }

        p {
          display: inline;
          max-width: 100%;
          font-size: 13px;

          a.reversefootnote {
            border-bottom: 0;
            vertical-align: sub;
          }
        }
      }
    }
  }
}

.image-wrap,
.video-wrap {
  position: relative;
  margin: 20px auto;

  @include mq(tabletp) {
    margin: 30px auto;
  }

  @include mq(laptop) {
    margin: 40px auto;
  }

  &:first-child {
    margin-top: 0;
  }
}

.image-wrap img {
  margin: 0 auto;
}

.video {
  position: relative;
  width: 100%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
