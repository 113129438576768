// - - - - - - - - - - - - - - - - - -

// Basics

// - - - - - - - - - - - - - - - - - -


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Basics

html, body {
  height: 100%;
}

body {
  background: $background-color;
  font-family: $body-font;
  font-weight: $regular-weight;
  font-style: normal;
  line-height: 1;
  color: $text-medium-color;
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Typography

h1, h2, h3, h4, h5, h6 {
  font-family: $blog-title-font;
  color: $text-medium-color;
  margin-right: 0px;

  &:first-child {
    margin-top: 0;
  }
}

h1, .h1 {
  font-size: $h1-small;
  font-weight: $h1-weight;
  line-height: $h1-lineheight;
  letter-spacing: $h1-spacing;

  @include mq(tabletp) {
    font-size: $h1-medium;
  }

  @include mq(laptop) {
    font-size: $h1-large;
  }

  a {
    font-weight: $h1-weight;
  }
}

h2, .h2 {
  margin-top: 40px;
  font-size: $h2-small;
  font-weight: $h2-weight;
  line-height: $h2-lineheight;
  letter-spacing: $h2-spacing;

  @include mq(tabletp) {
    font-size: $h2-medium;
  }

  @include mq(laptop) {
    font-size: $h2-large;
  }

  a {
    font-weight: $h2-weight;
  }
}

h3, .h3 {
  margin-top: 40px;
  font-size: $h3-small;
  font-weight: $h3-weight;
  line-height: $h3-lineheight;
  letter-spacing: $h3-spacing;

  @include mq(tabletp) {
    font-size: $h3-medium;
  }

  @include mq(laptop) {
    font-size: $h3-large;
  }
}

h4, .h4 {
  margin-top: 25px;
  font-size: $h4-small;
  font-weight: $h4-weight;
  line-height: $h4-lineheight;
  letter-spacing: $h4-spacing;

  @include mq(tabletp) {
    font-size: $h4-medium;
  }

  @include mq(laptop) {
    font-size: $h4-large;
  }
}

h5, .h5 {
  margin-top: 15px;
  font-size: $h5-small;
  font-weight: $h5-weight;
  line-height: $h5-lineheight;
  letter-spacing: $h5-spacing;

  @include mq(tabletp) {
    font-size: $h5-medium;
  }

  @include mq(laptop) {
    font-size: $h5-large;
  }
}

h6, .h6 {
  margin-top: 15px;
  font-size: $h6-small;
  font-weight: $h6-weight;
  line-height: $h6-lineheight;
  letter-spacing: $h6-spacing;

  @include mq(tabletp) {
    font-size: $h6-medium;
  }

  @include mq(laptop) {
    font-size: $h6-large;
  }
}

p, ul, ol {
  margin-top: 15px;
  font-size: $p-small;
  font-weight: $p-weight;
  line-height: $p-lineheight;
  letter-spacing: $p-spacing;

  @include mq(phonel) {
    font-size: $p-small;
  }

  @include mq(tabletp) {
    font-size: $p-medium;
  }

  @include mq(laptop) {
    font-size: $p-large;
  }

  strong {
    font-weight: $bold-weight;
  }

  &:first-child {
    margin-top: 0;
  }
}

ul, ol {
  list-style-position: inside;
}

/* Code segments in a block */
.highlight {
  color: $highlight-color;
}

/* Code segments inlined */
.code {
  color: $inline-code-color;
}

blockquote {
  margin-top: 15px;
  font-family: $title-font;
  font-size: $blockquote-small;
  font-weight: $blockquote-weight;
  letter-spacing: $blockquote-spacing;
  line-height: $blockquote-lineheight;

  @include mq(tabletp) {
    font-size: $blockquote-medium;
  }

  @include mq(laptop) {
    font-size: $blockquote-large;
  }

  p {
    font-size: $blockquote-small;
    font-weight: $blockquote-weight;
    letter-spacing: $blockquote-spacing;
    line-height: $blockquote-lineheight;

    @include mq(tabletp) {
      font-size: $blockquote-medium;
    }

    @include mq(laptop) {
      font-size: $blockquote-large;
    }
  }
}

a {
  text-decoration: none;
  color: $text-dark-color;
  font-weight: $bold-weight;
  @include transition(all 0.15s ease-in-out);

  &:hover {
    color: $accent-color;
  }
}

.button {
  display: inline-block;
  padding: 9px 10px 10px;
  font-size: 13px;
  line-height: 1;
  border: 2px solid $accent-color;
  border-radius: 3px;
  background: $accent-color;
  color: $background-color;
  font-weight: $button-weight;
  letter-spacing: $button-spacing;
  cursor: pointer;

  @include mq(tabletp) {
    padding: 11px 20px 12px;
    font-size: 15px;
  }

  &:hover {
    background: adjust-hue($accent-color,15%);
    border-color: adjust-hue($accent-color,15%);
    color: $background-color;
  }

  svg {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
      margin-left: 5px;
    }

    @include mq(tabletp) {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }
}

.button--large {
  padding: 15px 20px 16px;
  font-size: 13px;

  @include mq(tabletp) {
    padding: 19px 40px 20px;
    font-size: 17px;
  }
}

.button--light {
  background: transparent;
  border-color: $text-dark-color;
  color: $text-dark-color;

  &:hover {
    background: $accent-color;
    border-color: $accent-color;
    color: $background-color;
  }
}

.button--overlay {
  background: transparent;
  border-color: $overlay-text-color;
  color: $overlay-text-color;

  &:hover {
    background: $overlay-text-color;
    border-color: $overlay-text-color;
    color: $accent-color;
  }
}

hr {
  margin: 40px 0;
  border: 0;
  height: 2px;
  background: $border-color;
}

::selection 	 { background: $accent-color; color: $background-color; }
::-moz-selection { background: $accent-color; color: $background-color; }



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Page loading

.page-loader {
  display: none;
}

.page {
  @include transition(all 0.4s ease-in-out);
}

body.loading {

  .loader {
    opacity: 1;
    visibility: visible;
    @include transition(all 0.4s ease-in-out 1s);
  }

  .page {
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.4s ease-in-out);
  }

  .footer {
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.4s ease-in-out);
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  @include translate(-50%,-50%);
  width: 60px;
  height: 15px;

  opacity: 0;
  visibility: hidden;
  @include transition(all 0.2s ease-in-out);

  @include mq(tabletl) {
    left: 65%;
  }

  @include mq(desktop) {
    left: 62.5%;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: rgba($text-dark-color,0.2);
  }
}

.page,
.page__content {
  float: left;
  clear: both;
  width: 100%;
}

.page {

  @include mq(tabletl) {
    padding-left: calc(min(20%, 250px));
  }

  @include mq(desktop) {
    padding-left: calc(min(20%, 250px));
  }
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Page sections

.featured-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include mq(tabletl) {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    padding-bottom: 0;
    width: 30%;
  }
}

section {
  margin-top: 40px;

  @include mq(tabletp) {
    margin-top: 50px;
  }

  @include mq(tabletl) {
    margin-top: 60px;
  }

  @include mq(laptop) {
    margin-top: 80px;
  }
}

.wrap {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;

  @include mq(tabletp) {
    padding: 0 40px;
  }

  @include mq(tabletl) {
    padding: 0;
  }

  @include mq(laptop) {

  }
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Buy CTA

.buy-cta {
  position: fixed;
  bottom: 0;
  right: 0;
}

.buy-cta__link {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  background: #dc5b5d;

  @include mq(tabletp) {
    width: 60px;
    height: 60px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    @include translate(-50%,-50%);
    fill: #fff;

    @include mq(tabletp) {
      width: 30px;
      height: 30px;
    }
  }

  &:hover {

    .buy-cta__text {
      @include translate(0,0);
      opacity: 1;
    }
  }
}

.buy-cta__text {
  position: absolute;
  top: 0;
  right: 40px;
  background: #dc5b5d;
  padding: 14px 10px 13px 17px;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  white-space: nowrap;

  @include transition(all 0.15s ease-in-out);
  @include translate(0,20px);
  opacity: 0;

  @include mq(tabletp) {
    right: 60px;
    padding: 22px 14px 23px 25px;
    font-size: 15px;
    @include translate(0,30px);
  }
}
